<template>
<div/>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',
    data: () => ({
      links: [],
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
